<template>
  <div :loading="loading" class="tradeData-container">
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <div class="common-screen-container">
      <div v-if="!$vuex.state.onlyCompany" class="common-input-container">
        <span>所属公司:</span>
        <el-select
          class="common-screen-input"
          v-model="params.companyId"
          @change="companyFun"
          placeholder="请选择"
          filterable
        >
          <el-option
            v-for="item in rolesCompany"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>所属部门:</span>
        <el-cascader
          class="common-screen-input"
          v-model="params.departId"
          :options="selectDepart"
          @change="commonFun"
          :props="{
            label: 'name',
            children: 'child',
            value: 'id',
            emitPath: false,
          }"
        ></el-cascader>
      </div>
      <br />
      <!-- <div class="common-input-container">
        <el-date-picker
          v-model="params.datePicker"
          :clearable="false"
          @change="
            () => {
              changeDate();
            }
          "
          type="daterange"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </div>
      <div class="common-input-container">
        <el-radio-group v-model="params.type" @change="changeRadio">
          <el-radio-button :label="1">近7天</el-radio-button>
          <el-radio-button :label="2">近30天</el-radio-button>
          <el-radio-button :label="3">近90天</el-radio-button>
          <el-radio-button :label="0">昨天</el-radio-button>
        </el-radio-group>
      </div> -->
      <div class="common-input-container">
        <common-lately-date @commonGetDate="commonGetDate" :commonRadio="commonRadio" :startDate="params.startDate" :endDate="params.endDate" ref="commonReset"></common-lately-date>
      </div>
      <el-button
        class="common-screen-btn"
        type="primary"
        @click="
          () => {
            getTradeData();
          }
        "
        >查 询</el-button
      >
      <el-button class="common-screen-btn" plain @click="() => resetBtn()"
        >重 置</el-button
      >
    </div>
    <div class="common-upload-container" style="margin: 0">
      <el-button class="common-screen-btn" type="primary" :loading="btnLoading"
        >上传数据表格</el-button
      >
      <input
        v-if="!btnLoading"
        class="common-upload-input"
        @input="
          (e) => {
            uploadTradeXslx(e);
          }
        "
        type="file"
      />
    </div>
    <el-button class="common-screen-btn" @click="() => download()"
      >导出数据表格</el-button
    >
    <el-button class="common-screen-btn" type="text" @click="() => downloadTemplate()"
      >模板下载</el-button
    >
    <div class="tradeData-content">
      <table class="tradeData-table">
        <tbody>
          <tr>
            <th>咨询量</th>
            <th>接待量</th>
            <th>销售量</th>
            <th>销售额（元）</th>
            <th>转化率</th>
          </tr>
          <tr>
            <td>{{ serveData.consultationNum || "--" }}</td>
            <td>{{ serveData.receptionNum || "--" }}</td>
            <td>{{ serveData.tradeNum || "--" }}</td>
            <td>{{ serveData.tradeMoney || "--" }}</td>
            <td>{{ serveData.conversionRate || "--" }}</td>
          </tr>
        </tbody>
      </table>
      <div id="echartsRadar"></div>
    </div>
    <el-table class="common-table" :data="tableData" @sort-change="tableSort">
      <el-table-column prop="shopLogo" width="160px">
        <template slot-scope="scope">
          <span>
            <img
              class="common-iconImg"
              v-if="scope.row.shopLogo"
              :src="scope.row.shopLogo"
            />
            <span v-if="!scope.row.shopLogo"
              ><i class="el-icon-user common-iconImg-MR"></i
            ></span>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="shopName"
        label="店铺名称"
        :formatter="tableColumn"
        width="180px"
      ></el-table-column>
      <el-table-column
        prop="consultationNum"
        label="咨询量"
        sortable="custom"
        :formatter="tableColumn"
        width="180px"
      ></el-table-column>
      <el-table-column
        prop="receptionNum"
        label="接待量"
        sortable="custom"
        :formatter="tableColumn"
        width="180px"
      ></el-table-column>
      <el-table-column
        prop="tradeNum"
        label="销售量"
        sortable="custom"
        :formatter="tableColumn"
        width="180px"
      ></el-table-column>
      <el-table-column
        prop="tradeMoney"
        label="销售额（元）"
        sortable="custom"
        :formatter="tableColumn"
        width="180px"
      ></el-table-column>
      <el-table-column
        prop="conversionRate"
        label="转化率"
        sortable="custom"
        :formatter="tableColumn"
        width="180px"
      ></el-table-column>
      <el-table-column label="操作" width="180px" fixed="right">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.shopId"
            type="text"
            size="small"
            @click="
              () => {
                $router.push({
                  path: '/data/customer-trade-data/customer-trade-detail',
                  query: {
                    shopId: scope.row.shopId,
                    shopName: scope.row.shopName,
                    startTime: params.startDate,
                    endTime: params.endDate,
                  },
                });
              }
            "
            >查看详情</el-button
          >
          <el-button v-else type="text" style="color: #999" size="small"
            >查看详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.pageNum"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
  </div>
</template>
<script>
import Breadcrumb from "../../components/common/breadcrumb.vue";
import { getLatelyDay, DateTransform, Config, tableColumn } from "../../utils/index.js";
import { getTradeData, getTradeDataList, uploadTradeXslx } from "../../service/dataInfo.js";
import { commonDepartAll } from "../../service/common.js";
import CommonLatelyDate from "../../components/common/commonLatelyDate.vue"
export default {
  components: { Breadcrumb, CommonLatelyDate },
  data() {
    return {
      DateTransform,
      tableColumn,
      btnLoading: false,
      loading: false,
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "数据", isLink: false },
        { title: "店铺交易数据", isLink: false },
      ],
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100],
      },
      commonRadio: 3,
      params: {
        startDate: DateTransform(getLatelyDay(90)[0]),
        endDate: DateTransform(getLatelyDay(90)[1]),
        pageNum: 1,
        pageSize: 10,
      },
      serveData: {}, // 店铺服务数据
      selectDepart: [], // 部门下拉
      rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company, // 所属公司下拉
      tableData: [],
    };
  },
  mounted() {
    this.echartsRadar();
  },
  created() {
    if (this.rolesCompany && this.rolesCompany.length) {
      this.params.companyId = this.rolesCompany[0].id;
      this.commonDepartAll(this.rolesCompany[0].id);
    }
    this.getTradeData();
  },
  methods: {
    commonGetDate (startTime, endTime, commonRadio) {
      this.params.startDate = startTime
      this.params.endDate = endTime
      this.commonRadio = commonRadio
      this.getTradeData()
    },
    commonFun() {
      this.params.page = 1;
      this.params.pageSize = 10;
      this.getTradeData();
    },
    async uploadTradeXslx(e) {
      // 上传员工表格
      this.btnLoading = true;
      let file = e.target.files[0];
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (format != "xls" && format != "xlsx") {
        this.$message.error("仅支持xls、xlsx格式文件");
        e.target.value = "";
        this.btnLoading = false;
        return;
      }
      await uploadTradeXslx({ file: file });
      this.btnLoading = false;
      e.target.value = "";
      this.$message.success("上传成功");
      this.getTradeData()
    },
    downloadTemplate() {
      // 下载模板-暂时写死
      window.location.href =
        "https://sy-my.oss-cn-zhangjiakou.aliyuncs.com/sy/file/%E6%91%A9%E4%BA%BF%E9%94%80%E5%94%AE%E6%95%B0%E6%8D%AE%E6%A8%A1%E6%9D%BF.xlsx";
    },
    download() {
      let params = { ...this.params };
      delete params.pageNum;
      delete params.pageSize;
      let departIdType = typeof params.departId;
      if (departIdType == "object" || departIdType == "undefined") {
        delete params.departId;
      }
      let url = Config.api.url + "/dataRecord/dataCustomerTrade/excel/detailExport";
      let paramArr = [];
      for (let item in params) {
        if (params[item]) {
          paramArr.push(item + "=" + params[item]);
        }
      }
      url = url + "?" + paramArr.join("&");
      window.location.href = url;
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.pageNum = e;
      }
      this.getTradeData();
    },
    async getTradeDataList() {
      // 获取表格数据
      let params = { ...this.params };
      if (!params.sortOrder) {
        delete params.sortField;
        delete params.sortOrder;
      }
      let departIdType = typeof params.departId;
      if (departIdType == "object" || departIdType == "undefined") {
        delete params.departId;
      }
      let resData = (await getTradeDataList(params)).data;
      this.pagination.total = resData.total;
      this.pagination.pageSize = resData.size;
      this.tableData = resData.records;
    },
    resetBtn() {
      this.commonRadio = 3
      this.params = {
        startDate: DateTransform(getLatelyDay(90)[0]),
        endDate: DateTransform(getLatelyDay(90)[1]),
        pageNum: 1,
        pageSize: 10,
      }
      if (this.rolesCompany && this.rolesCompany.length) {
        this.params.companyId = this.rolesCompany[0].id;
        this.commonDepartAll(this.rolesCompany[0].id);
      }
      this.$refs.commonReset.resetFun(3, DateTransform(getLatelyDay(90)[0]), DateTransform(getLatelyDay(90)[1]))
      this.getTradeData();
    },
    companyFun(id) {
      //选择公司筛选
      this.params.departId = [];
      this.getTradeData();
      this.commonDepartAll(id);
    },
    async commonDepartAll(id) {
      // 部门 下拉
      let resData = (await commonDepartAll({}, id)).data;
      this.selectDepart = resData ? resData : [];
    },
    changeDate() {
      // 选择时间时清空近几天类型选择
      this.params.type = null;
      this.getTradeData();
    },
    async getTradeData() {
      // 索取服务数据
      let params = { ...this.params };
      this.loading = true;
      if (!params.sortOrder) {
        delete params.sortField;
        delete params.sortOrder;
      }
      let departIdType = typeof params.departId;
      if (departIdType == "object" || departIdType == "undefined") {
        delete params.departId;
      }
      delete params.pageNum;
      delete params.pageSize;
      let resData = (await getTradeData(params)).data;
      this.getTradeDataList();
      this.serveData = resData[0];
      this.loading = false;
    },
    tableSort(e) {
      let params = { ...this.params };
      params.sortField = e.prop;
      switch (e.order) {
        case "ascending":
          params.sortOrder = "ascend";
          break;
        case "descending":
          params.sortOrder = "descend";
          break;
        default:
          params.sortOrder = null;
      }
      this.params = params;
      this.getTradeDataList();
    },
    echartsRadar() {
      // 雷达图
      let myChart = this.$echarts.init(document.getElementById("echartsRadar"));

      let option = {
        // color: ['#3a7bff', '#3b90ff', '#5cb6ff'],
        color: ["#3a7bff", "#3b90ff", "#5cb6ff"],

        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c}",
        },
        legend: {
          data: ["咨询人数", "接待人数", "销售量"],
        },
        series: [
          {
            type: "funnel",
            left: "20%",
            top: 40,
            bottom: 0,
            width: "60%",
            min: 0,
            max: 100,
            minSize: "0%",
            maxSize: "100%",
            sort: "descending",
            gap: 8,
            label: {
              formatter: "{b}: {c}%",
            },
            emphasis: {
              label: {
                position: "inside",
                formatter: "{b}: {c}",
                lableSize: 20,
              },
            },
            labelLine: {
              length: 30,
              lineStyle: {
                width: 1,
                type: "solid",
              },
            },
            data: [
              { value: 39, name: "咨询人数" },
              { value: 39, name: "接待人数" },
              { value: 28, name: "销售量" },
            ],
          },
          {
            type: "funnel",
            left: "20%",
            top: 40,
            bottom: 0,
            width: "60%",
            min: 0,
            max: 100,
            minSize: "0%",
            maxSize: "100%",
            sort: "descending",
            gap: 8,
            label: {
              position: "inside",
              formatter: " {c}",
              color: "#fff",
            },
            labelLine: {
              length: 30,
              lineStyle: {
                width: 1,
                type: "solid",
              },
            },
            data: [
              { value: 39, name: "咨询人数" },
              { value: 39, name: "接待人数" },
              { value: 28, name: "销售量" },
            ],
          },
        ],
      };
      myChart.setOption(option);
    },
    changeRadio(e) {
      let date;
      switch (e) {
        case 0:
          date = 0;
          break;
        case 1:
          date = 7;
          break;
        case 2:
          date = 30;
          break;
        case 3:
          date = 90;
          break;
      }
      this.params.datePicker = getLatelyDay(date);
      this.getTradeData();
    },
  },
};
</script>
<style lang="less" scoped>
.tradeData-container {
  text-align: left;
  .iconImg {
    width: 40px;
  }
  .tradeData-content {
    margin: 48px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    #echartsRadar {
      flex: 1;
      height: 300px;
      display: none;
    }
    .tradeData-table {
      display: table;
      width: 100%;
      border-collapse: collapse;
      td,
      th {
        text-align: center;
        border: 1px solid #c9c9c9;
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
      }
      th {
        background: #f5f5f5;
        height: 60px;
        line-height: 60px;
        font-weight: 600;
      }
      td {
        height: 85px;
        line-height: 85px;
      }
    }
  }
}
</style>
